<template>
	<modal class="NoxModalPortraitSettings" name="NoxModalPortraitSettings" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'addPortraitQuestion'">Добавление вопроса</span>
					<span v-else-if="noxType == 'addPortraitAnswer'">Добавление ответа</span>
					<span v-else-if="noxType == 'addPortraitResult'">Добавление портрета</span>
					<span v-else-if="noxType == 'editPortraitQuestion'">Редактирование вопроса</span>
					<span v-else-if="noxType == 'editPortraitAnswer'">Редактирование ответа</span>
					<span v-else-if="noxType == 'editPortraitResult'">Редактирование портрета</span>
					<span v-else-if="noxType == 'deletePortraitQuestion'">Удаление вопроса</span>
					<span v-else-if="noxType == 'deletePortraitAnswer'">Удаление ответа</span>
					<span v-else-if="noxType == 'deletePortraitResult'">Удаление портрета</span>
					<span v-else-if="noxType == 'deletePortraitRTest'">Удаление теста</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="['addPortraitQuestion', 'editPortraitQuestion'].includes(noxType)">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Статус вопроса:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxStatus)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForStatus"></toggle-button>
								<div v-html="noxAlertStatus"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Перемешивать ответы:</div>
							<div class="nox_modal_info_row_value">
								<toggle-button class="nox_toggle_button" :value="toggleValue(noxIsRandom)" :sync="true" :width="42" :height="26" :color="{ checked: '#00FF0080' }" @change="toggleChangeForIsRandom"></toggle-button>
								<div v-html="noxAlertIsRandom"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">HTML вопрос (RU):</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxQuestionRU" placeholder="html текст вопроса (ru)"></textarea>
								<div v-html="noxAlertQuestionRU"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">HTML вопрос (EN):</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxQuestionEN" placeholder="html текст вопроса (en)"></textarea>
								<div v-html="noxAlertQuestionEN"></div>
							</div>
						</div>
					</div>
					<div v-else-if="['addPortraitAnswer', 'editPortraitAnswer'].includes(noxType)">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Вес ответа:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.number="noxWeight" placeholder="вес ответа" autocomplete="off">
								<div v-html="noxAlertWeight"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Множитель ответа:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.number="noxMultiplier" placeholder="множитель ответа" autocomplete="off">
								<div v-html="noxAlertMultiplier"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Текст ответа (RU):</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxAnswerRU" placeholder="текст ответа (ru)" autocomplete="off">
								<div v-html="noxAlertAnswerRU"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">Текст ответа (EN):</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxAnswerEN" placeholder="текст ответа (en)" autocomplete="off">
								<div v-html="noxAlertAnswerEN"></div>
							</div>
						</div>
					</div>
					<div v-else-if="['addPortraitResult', 'editPortraitResult'].includes(noxType)">
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">MIN интервала:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.number="noxMin" placeholder="min интервала" autocomplete="off">
								<div v-html="noxAlertMin"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">MAX интервала:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.number="noxMax" placeholder="max интервала" autocomplete="off">
								<div v-html="noxAlertMax"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">Цвет риска:</div>
							<div class="nox_modal_info_row_value">
								<input type="text" class="nox_input" v-model.trim="noxColor" placeholder="цвет риска" autocomplete="off">
								<div v-html="noxAlertColor"></div>
							</div>
						</div>
						<div class="nox_modal_info_row">
							<div class="nox_modal_info_row_label">HTML портрет (RU):</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxResultRU" placeholder="html текст портрета (ru)"></textarea>
								<div v-html="noxAlertResultRU"></div>
							</div>
						</div>
						<div class="nox_modal_info_row last">
							<div class="nox_modal_info_row_label">HTML портрет (EN):</div>
							<div class="nox_modal_info_row_value">
								<textarea class="nox_textarea small" v-model.trim="noxResultEN" placeholder="html текст портрета (en)"></textarea>
								<div v-html="noxAlertResultEN"></div>
							</div>
						</div>
					</div>
					<div v-else-if="noxType == 'deletePortraitQuestion'">Вы действительно хотите удалить вопрос?</div>
					<div v-else-if="noxType == 'deletePortraitAnswer'">Вы действительно хотите удалить ответ?</div>
					<div v-else-if="noxType == 'deletePortraitResult'">Вы действительно хотите удалить портрет?</div>
					<div v-else-if="noxType == 'deletePortraitRTest'">Вы действительно хотите удалить тест?</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-if="['addPortraitQuestion', 'addPortraitAnswer', 'addPortraitResult'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="['editPortraitQuestion', 'editPortraitAnswer', 'editPortraitResult'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
					<button type="button" class="nox_button common red" @click="axios" v-else-if="['deletePortraitQuestion', 'deletePortraitAnswer', 'deletePortraitResult', 'deletePortraitRTest'].includes(noxType)" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertMin: '',
			noxAlertMax: '',
			noxAlertColor: '',
			noxAlertStatus: '',
			noxAlertWeight: '',
			noxAlertMultiplier: '',
			noxAlertQuestionRU: '',
			noxAlertQuestionEN: '',
			noxAlertAnswerRU: '',
			noxAlertAnswerEN: '',
			noxAlertResultRU: '',
			noxAlertResultEN: '',
			noxAlertIsRandom: '',
			noxId: 0,
			noxQid: 0,
			noxMin: '',
			noxMax: '',
			noxType: '',
			noxIndex: 0,
			noxColor: '',
			noxStatus: 0,
			noxWeight: '',
			noxMultiplier: '',
			noxQuestionRU: '',
			noxQuestionEN: '',
			noxAnswerRU: '',
			noxAnswerEN: '',
			noxResultRU: '',
			noxResultEN: '',
			noxIsRandom: 0,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxQid = event.params.qid;
				this.noxMin = '';
				this.noxMax = '';
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxColor = '';
				this.noxStatus = 0;
				this.noxWeight = '';
				this.noxMultiplier = '';
				this.noxQuestionRU = '';
				this.noxQuestionEN = '';
				this.noxAnswerRU = '';
				this.noxAnswerEN = '';
				this.noxResultRU = '';
				this.noxResultEN = '';
				this.noxIsRandom = 0;
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editPortraitQuestion') {

					this.noxStatus = this.$parent.noxPortraitQuestions[this.noxIndex].status;
					this.noxQuestionRU = this.$parent.noxPortraitQuestions[this.noxIndex].question_ru;
					this.noxQuestionEN = this.$parent.noxPortraitQuestions[this.noxIndex].question_en;
					this.noxIsRandom = this.$parent.noxPortraitQuestions[this.noxIndex].is_random;
				}
				else if (this.noxType == 'editPortraitAnswer') {

					this.noxWeight = this.$parent.noxPortraitAnswers[this.noxQid][this.noxIndex].weight;
					this.noxMultiplier = this.$parent.noxPortraitAnswers[this.noxQid][this.noxIndex].multiplier;
					this.noxAnswerRU = this.$parent.noxPortraitAnswers[this.noxQid][this.noxIndex].answer_ru;
					this.noxAnswerEN = this.$parent.noxPortraitAnswers[this.noxQid][this.noxIndex].answer_en;
				}
				else if (this.noxType == 'editPortraitResult') {

					this.noxMin = this.$parent.noxPortraitResults[this.noxIndex].min;
					this.noxMax = this.$parent.noxPortraitResults[this.noxIndex].max;
					this.noxColor = this.$parent.noxPortraitResults[this.noxIndex].color;
					this.noxResultRU = this.$parent.noxPortraitResults[this.noxIndex].result_ru;
					this.noxResultEN = this.$parent.noxPortraitResults[this.noxIndex].result_en;
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertMin = '';
				this.noxAlertMax = '';
				this.noxAlertColor = '';
				this.noxAlertStatus = '';
				this.noxAlertWeight = '';
				this.noxAlertMultiplier = '';
				this.noxAlertQuestionRU = '';
				this.noxAlertQuestionEN = '';
				this.noxAlertAnswerRU = '';
				this.noxAlertAnswerEN = '';
				this.noxAlertResultRU = '';
				this.noxAlertResultEN = '';
				this.noxAlertIsRandom = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id вопроса.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id вопроса.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать статус вопроса.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный статус вопроса.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать перемешивание ответов вопроса.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верное перемешивание ответов вопроса.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать HTML текст вопроса (ru).'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верный HTML текст вопроса (ru).'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать HTML текст вопроса (en).'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верный HTML текст вопроса (en).'; }
				else if (i == 11) { this.noxTemp = 'Необходимо указать id ответа.'; }
				else if (i == 12) { this.noxTemp = 'Необходимо указать верный id ответа.'; }
				else if (i == 13) { this.noxTemp = 'Необходимо указать вес ответа.'; }
				else if (i == 14) { this.noxTemp = 'Необходимо указать верный вес ответа.'; }
				else if (i == 15) { this.noxTemp = 'Необходимо указать множитель ответа.'; }
				else if (i == 16) { this.noxTemp = 'Необходимо указать верный множитель ответа.'; }
				else if (i == 17) { this.noxTemp = 'Необходимо указать текст ответа (ru).'; }
				else if (i == 18) { this.noxTemp = 'Необходимо указать верный текст ответа (ru).'; }
				else if (i == 19) { this.noxTemp = 'Необходимо указать текст ответа (en).'; }
				else if (i == 20) { this.noxTemp = 'Необходимо указать верный текст ответа (en).'; }
				else if (i == 21) { this.noxTemp = 'Необходимо указать id портрета.'; }
				else if (i == 22) { this.noxTemp = 'Необходимо указать верный id портрета.'; }
				else if (i == 23) { this.noxTemp = 'Необходимо указать MIN интервала портрета.'; }
				else if (i == 24) { this.noxTemp = 'Необходимо указать верный MIN интервала портрета.'; }
				else if (i == 25) { this.noxTemp = 'Необходимо указать MAX интервала портрета.'; }
				else if (i == 26) { this.noxTemp = 'Необходимо указать верный MAX интервала портрета. (MAX >= MIN)'; }
				else if (i == 27) { this.noxTemp = 'Необходимо указать цвет риска портрета.'; }
				else if (i == 28) { this.noxTemp = 'Необходимо указать верный цвет риска портрета.'; }
				else if (i == 29) { this.noxTemp = 'Необходимо указать HTML текст портрета (ru).'; }
				else if (i == 30) { this.noxTemp = 'Необходимо указать верный HTML текст портрета (ru).'; }
				else if (i == 31) { this.noxTemp = 'Необходимо указать HTML текст портрета (en).'; }
				else if (i == 32) { this.noxTemp = 'Необходимо указать верный HTML текст портрета (en).'; }
				else if (i == 33) { this.noxTemp = 'Необходимо указать id теста.'; }
				else if (i == 34) { this.noxTemp = 'Необходимо указать верный id теста.'; }
				else if (i == 35) { this.noxTemp = 'Id вопроса не найден в системе.'; }
				else if (i == 36) { this.noxTemp = 'Id ответа не найден в системе.'; }
				else if (i == 37) { this.noxTemp = 'Id портрета не найден в системе.'; }
				else if (i == 38) { this.noxTemp = 'Id теста не найден в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['addPortraitQuestion', 'editPortraitQuestion'].includes(_this.noxType)) {
						if (!_this.noxQuestionRU) {
							_this.noxAlertQuestionRU = _this.getError(7);
						}
						if (!_this.noxQuestionEN) {
							_this.noxAlertQuestionEN = _this.getError(9);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/portrait/questions';
							config.data = { id: _this.noxId, status: _this.noxStatus, question_ru: _this.noxQuestionRU, question_en: _this.noxQuestionEN, is_random: _this.noxIsRandom };
							config.method = _this.noxType == 'addPortraitQuestion' ? 'post' : 'patch';
						}
					}
					else if (['addPortraitAnswer', 'editPortraitAnswer'].includes(_this.noxType)) {
						if (!_this.noxWeight) {
							_this.noxAlertWeight = _this.getError(13);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxWeight) || _this.noxWeight < 1) {
							_this.noxAlertWeight = _this.getError(14);
						}
						if (!_this.noxMultiplier) {
							_this.noxAlertMultiplier = _this.getError(15);
						}
						else if (!_this.$store.state.noxRegexIntOrFloatNumbers.test(_this.noxMultiplier) || _this.noxMultiplier < 0.1) {
							_this.noxAlertMultiplier = _this.getError(16);
						}
						if (!_this.noxAnswerRU) {
							_this.noxAlertAnswerRU = _this.getError(17);
						}
						if (!_this.noxAnswerEN) {
							_this.noxAlertAnswerEN = _this.getError(19);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/portrait/answers';
							config.data = { id: _this.noxId, qid: _this.noxQid, weight: _this.noxWeight, multiplier: _this.noxMultiplier, answer_ru: _this.noxAnswerRU, answer_en: _this.noxAnswerEN };
							config.method = _this.noxType == 'addPortraitAnswer' ? 'post' : 'patch';
						}
					}
					else if (['addPortraitResult', 'editPortraitResult'].includes(_this.noxType)) {
						if (!_this.noxMin) {
							_this.noxAlertMin = _this.getError(23);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxMin) || _this.noxMin < 1) {
							_this.noxAlertMin = _this.getError(24);
						}
						if (!_this.noxMax) {
							_this.noxAlertMax = _this.getError(25);
						}
						else if (!_this.$store.state.noxRegex_d.test(_this.noxMax) || _this.noxMax < 1 || _this.noxMax < _this.noxMin) {
							_this.noxAlertMax = _this.getError(26);
						}
						if (!_this.noxColor) {
							_this.noxAlertColor = _this.getError(27);
						}
						else if (!_this.$store.state.noxRegexENLettersAndNumbers.test(_this.noxColor) || _this.noxColor.length !== 6) {
							_this.noxAlertColor = _this.getError(28);
						}
						if (!_this.noxResultRU) {
							_this.noxAlertResultRU = _this.getError(29);
						}
						if (!_this.noxResultEN) {
							_this.noxAlertResultEN = _this.getError(31);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/portrait/results';
							config.data = { id: _this.noxId, min: _this.noxMin, max: _this.noxMax, color: _this.noxColor, result_ru: _this.noxResultRU, result_en: _this.noxResultEN };
							config.method = _this.noxType == 'addPortraitResult' ? 'post' : 'patch';
						}
					}
					else if (_this.noxType == 'deletePortraitQuestion') {
						config.url = '/v2/admin/account/portrait/questions';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}
					else if (_this.noxType == 'deletePortraitAnswer') {
						config.url = '/v2/admin/account/portrait/answers';
						config.data = { id: _this.noxId, qid: _this.noxQid };
						config.method = 'delete';
					}
					else if (_this.noxType == 'deletePortraitResult') {
						config.url = '/v2/admin/account/portrait/results';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}
					else if (_this.noxType == 'deletePortraitRTest') {
						config.url = '/v2/admin/account/portrait/rtests';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (['addPortraitQuestion', 'editPortraitQuestion'].includes(_this.noxType)) {
									if (_this.noxType == 'addPortraitQuestion') {
										_this.$parent.noxPortraitQuestions.push(data.data);
									}
									else if (_this.noxType == 'editPortraitQuestion') {
										_this.$parent.noxPortraitQuestions.splice(_this.noxIndex, 1, data.data);
									}
								}
								else if (['addPortraitAnswer', 'editPortraitAnswer'].includes(_this.noxType)) {
									if (_this.noxType == 'addPortraitAnswer') {
										if (_this.$parent.noxPortraitAnswers[_this.noxQid] == undefined) {
											_this.$set(_this.$parent.noxPortraitAnswers, _this.noxQid, []);
										}
										_this.$parent.noxPortraitAnswers[_this.noxQid].push(data.data);
									}
									else if (_this.noxType == 'editPortraitAnswer') {
										_this.$parent.noxPortraitAnswers[_this.noxQid].splice(_this.noxIndex, 1, data.data);
									}
								}
								else if (['addPortraitResult', 'editPortraitResult'].includes(_this.noxType)) {
									if (_this.noxType == 'addPortraitResult') {
										_this.$parent.noxPortraitResults.push(data.data);
									}
									else if (_this.noxType == 'editPortraitResult') {
										_this.$parent.noxPortraitResults.splice(_this.noxIndex, 1, data.data);
									}
								}
								else if (_this.noxType == 'deletePortraitQuestion') {
									_this.$parent.noxPortraitQuestions = data.data;
									_this.$set(_this.$parent.noxPortraitAnswers, _this.noxId, []);
								}
								else if (_this.noxType == 'deletePortraitAnswer') {
									_this.$set(_this.$parent.noxPortraitAnswers, _this.noxQid, data.data);
								}
								else if (_this.noxType == 'deletePortraitResult') {
									_this.$parent.noxPortraitResults = data.data;
								}
								else if (_this.noxType == 'deletePortraitRTest') {
									_this.$parent.noxPortraitRTests.splice(_this.noxIndex, 1);
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addPortraitQuestion', 'editPortraitQuestion', 'deletePortraitQuestion'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'STATUS_IS_EMPTY') { _this.noxAlertStatus = _this.getError(3); }
										else if (data.response.data.error == 'STATUS_NOT_VALID') { _this.noxAlertStatus = _this.getError(4); }
										else if (data.response.data.error == 'IS_RANDOM_IS_EMPTY') { _this.noxAlertIsRandom = _this.getError(5); }
										else if (data.response.data.error == 'IS_RANDOM_NOT_VALID') { _this.noxAlertIsRandom = _this.getError(6); }
										else if (data.response.data.error == 'QUESTION_RU_IS_EMPTY') { _this.noxAlertQuestionRU = _this.getError(7); }
										else if (data.response.data.error == 'QUESTION_RU_NOT_VALID') { _this.noxAlertQuestionRU = _this.getError(8); }
										else if (data.response.data.error == 'QUESTION_EN_IS_EMPTY') { _this.noxAlertQuestionEN = _this.getError(9); }
										else if (data.response.data.error == 'QUESTION_EN_NOT_VALID') { _this.noxAlertQuestionEN = _this.getError(10); }
									}
									else if (['addPortraitAnswer', 'editPortraitAnswer', 'deletePortraitAnswer'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(11); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(12); }
										else if (data.response.data.error == 'QID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'QID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'WEIGHT_IS_EMPTY') { _this.noxAlertWeight = _this.getError(13); }
										else if (data.response.data.error == 'WEIGHT_NOT_VALID') { _this.noxAlertWeight = _this.getError(14); }
										else if (data.response.data.error == 'MULTIPLIER_IS_EMPTY') { _this.noxAlertMultiplier = _this.getError(15); }
										else if (data.response.data.error == 'MULTIPLIER_NOT_VALID') { _this.noxAlertMultiplier = _this.getError(16); }
										else if (data.response.data.error == 'ANSWER_RU_IS_EMPTY') { _this.noxAlertAnswerRU = _this.getError(17); }
										else if (data.response.data.error == 'ANSWER_RU_NOT_VALID') { _this.noxAlertAnswerRU = _this.getError(18); }
										else if (data.response.data.error == 'ANSWER_EN_IS_EMPTY') { _this.noxAlertAnswerEN = _this.getError(19); }
										else if (data.response.data.error == 'ANSWER_EN_NOT_VALID') { _this.noxAlertAnswerEN = _this.getError(20); }
									}
									else if (['addPortraitResult', 'editPortraitResult', 'deletePortraitResult'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(21); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(22); }
										else if (data.response.data.error == 'MIN_IS_EMPTY') { _this.noxAlertMin = _this.getError(23); }
										else if (data.response.data.error == 'MIN_NOT_VALID') { _this.noxAlertMin = _this.getError(24); }
										else if (data.response.data.error == 'MAX_IS_EMPTY') { _this.noxAlertMax = _this.getError(25); }
										else if (data.response.data.error == 'MAX_NOT_VALID') { _this.noxAlertMax = _this.getError(26); }
										else if (data.response.data.error == 'COLOR_IS_EMPTY') { _this.noxAlertColor = _this.getError(27); }
										else if (data.response.data.error == 'COLOR_NOT_VALID') { _this.noxAlertColor = _this.getError(28); }
										else if (data.response.data.error == 'RESULT_RU_IS_EMPTY') { _this.noxAlertResultRU = _this.getError(29); }
										else if (data.response.data.error == 'RESULT_RU_NOT_VALID') { _this.noxAlertResultRU = _this.getError(30); }
										else if (data.response.data.error == 'RESULT_EN_IS_EMPTY') { _this.noxAlertResultEN = _this.getError(31); }
										else if (data.response.data.error == 'RESULT_EN_NOT_VALID') { _this.noxAlertResultEN = _this.getError(32); }
									}
									else if (_this.noxType == 'deletePortraitRTest') {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(33); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(34); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['editPortraitQuestion', 'deletePortraitQuestion'].includes(_this.noxType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(35); }
									}
									else if (['editPortraitAnswer', 'deletePortraitAnswer'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(36); }
										else if (data.response.data.error == 'QID_NOT_FOUND') { _this.noxAlert = _this.getError(35); }
									}
									else if (['editPortraitResult', 'deletePortraitResult'].includes(_this.noxType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(37); }
									}
									else if (_this.noxType == 'deletePortraitRTest') {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(38); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			toggleValue: function(value) {
				return value > 0 ? true : false;
			},
			toggleChangeForStatus: function(event) {
				this.noxStatus = Number(event.value);
			},
			toggleChangeForIsRandom: function(event) {
				this.noxIsRandom = Number(event.value);
			},
			close: function() {
				this.$modal.hide('NoxModalPortraitSettings');
			}
		}
	}
</script>
